<template>
  <div class="investmentContainer">
    <!-- <div class="topNavigationBox">
      <div class="topNavigationInfo">
        <div class="getBack" @click="getBackFun()">
          <el-image style="width: 20px;height: 20px;" fit="cover" src="http://file.thinkervc.cn/33b5fp88hobk5hj3jbdyjh3lf29mde3tw4r9c0qn0zpye5hyufgftq23oiam0nj97xd3wt8zah2x909l.png"></el-image>
        </div>
        <div class="navigationTitle">找投资 </div>
      </div>
    </div>
    <div style="height: 44px;"></div> -->
    <div class="searchBox">
      <el-input @input="iptValFun" placeholder="请输入投资机构名称" v-model="paramsData.companyName" class="inputSelect"></el-input>
    </div>
    <div class="padding flex align-center justify-between text-34 navi-box">
      <div class="flex-sub flex align-center justify-center" style="color: #ff9834;">联合投资人</div>
      <div class="margin-lr" style="width: 0; height: 60rpx; border: 1px solid #ebebeb;"></div>
      <div class="flex-sub flex align-center justify-center" @click="jumpTo">中科心客基金</div>
    </div>
    <div class="padding flex align-center messageCard" v-for="(item) in messageCardList" :key="item.id">
      <div class="rel flex-sub flex flex-direction justify-between" style="height: 100%;">
        <div class="flex align-center justify-between">
          <div class="flex align-end">
            <div class="text-34 text-bold self-end" style="min-width: 53px;">{{ item.name }}</div>
            <div class="text-24" style="margin-left: 9px; color: #929292;">{{ item.position ? item.position : '无' }}</div>
          </div>
          <button class="flex flex-direction align-center btnLxw" hover-class="none" @click.stop="contactMe(item)">联系我</button>
        </div>
        <div class="padding-top-xl flex flex-direction">
          <span class="text-24 text-bold">{{ item.companyName ? item.companyName : '无' }}</span>
          <span class="text-24" style="margin-top: 9px; line-height: 24px;">投资领域：{{ item.investmentField ? item.investmentField : '无' }}</span>
        </div>
      </div>
    </div>
    <div @click="nextPage()" class="examineBut" v-if="totalShow == true">查看更多</div>
    <div v-if="messageCardList.length == 0" class="reminderText">
      没有更多了
    </div>
    <!-- 弹出框 -->
    <el-dialog title="" :visible.sync="imgShow" width="85%" center :show-close="false">
      <div>
        <el-image  fit="cover" :src="saveImg" style="width: 100%;height: 310px;"></el-image>
        <div class="saveText">保存图片，使用扫一扫添加联系人微信</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="popupBut" @click.stop="saveImageFun">保存图片</el-button>
      </span>
    </el-dialog>
    <div class="asideFloatBox" >
      <el-popover placement="top-start" title="请扫码在小程序内进行操作" width="220" trigger="click">
        <div style="text-align: center;">
          <el-image fill="contain" :src="require('@/assets/img/mobile/codeImg_1.png')" style="width: 10.9375rem;height: 10.9375rem;"></el-image>
        </div>

        <div style="width: 137px;height: 42px;display: flex;" slot="reference">
          <div class="imgContainer">
            <el-image fit="cover" src="http://file.thinkervc.cn/xvcdk2ln04s8m8jwvmvrktst7p64wlrrrzt7xy6url2e46m340kwz6hbbe27vqdfqzjfhiss9hz99pd1.png" style="width: 35px;height: 35px;"></el-image>
          </div>
          <span class="titleContainer" slot="reference">加入投资团</span>
        </div>
      </el-popover>
    </div>


  </div>
</template>

<script>
import { request } from '@/utils/request';
export default {
  data() {
    return {
      paramsData: {
        pageNum: 1,
        pageSize: 10,
        companyName: ""
      },
      total: 0,
      totalShow: false,
      imgShow: false,
      saveImg: "",
      messageCardList: []
    };
  },
  created() {
    this.messageCardInfo();
  },
  methods: {
    toMechanismForm() {},
    jumpTo() {
      this.$router.push({ name: "LookingInvestment", query: {} });
    },
    getBackFun() {
      this.$router.go(-1);
    },
    contactMe(data) {
      this.saveImg = data.imgUrl;
      this.imgShow = true;
    },
    saveImageFun() {
      var that = this;
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png");
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = "二维码";
        a.href = url;
        a.dispatchEvent(event);
      };
      image.src = that.saveImg;
      that.imgShow = false;
    },
    iptValFun(e) {
      console.log(e);
      this.paramsData = {
        pageNum: 1,
        pageSize: 10,
        companyName: e
      }
      this.messageCardInfo();
    },
    nextPage() {
      this.paramsData.pageNum = this.paramsData.pageNum + 1;
      this.messageCardInfo();
    },
    messageCardInfo() {
      var paramsData = this.paramsData;
      console.log(paramsData);
      let url = 'https://xinke.thinkervc.cn/api/applet/institutions/getInstitutionsList';
      url += '?pageNum=' + this.paramsData.pageNum + '&pageSize=' + this.paramsData.pageSize;
      if (this.paramsData.companyName) url += '&companyName=' + this.paramsData.companyName;
      request(url).then(res => {
        if (res.code === 200) {
          var list = res.data.records;
          if (this.paramsData.pageNum === 1) {
            this.messageCardList = list;
          } else {
            var data = this.messageCardList.concat(list);
            console.log(data, "11111111111");
            this.messageCardList = data;
          }
          if (list.length <= 10 && res.data.total < 10) {
            this.totalShow = false;
          } else {
            this.totalShow = true;
          }
          console.log(this.messageCardList);
        }
      })
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
  .topNavigationBox{
    position: fixed;
    width: 100%;
    height: 44px;
    background-color: #feb15f;
    left: 0;
    top: 0;
    z-index: 111111;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .topNavigationInfo{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }
  .getBack{
    position: absolute;
    width: 40px;
    height: 100%;
    left: 10px;
    display: flex;
    align-items: center;
    top: 0;
  }
  .navigationTitle{
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .justify-center{
    justify-content: center;
  }
  .align-center{
    align-items: center;
  }
  .justify-center{
    justify-content: center;
  }
  .flex-sub{
    flex: 1;
  }
  .flex{
    display: flex;
  }
  .text-34{
    font-size: 20px;
  }
  .padding{
    padding: 17px;
  }
  .margin-lr{
    margin-left: 17px;
    margin-right: 17px;
  }
  .flex-direction{
    flex-direction: column;
  }
  .rel{
    position: relative;
  }
  .align-end{
    align-items: flex-end;
  }
  .text-bold{
    font-weight: bold;
  }
  .self-end{
    align-self: flex-end;
  }
  .text-24{
    font-size: 14px;
  }
  .investmentContainer{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 10%;
  }
  .searchBox{
    padding: 19px;
    width: 100%;
    height: 124px;
    background-color: #feb15f;
  }
  .inputSelect{
    background-color: rgb(242, 242, 242);
    border-radius: 50px;
    border: 0;
    height: 38px;
  }
  ::v-deep .el-input__inner{
    background-color: rgb(242, 242, 242);
    border-radius: 50px;
    height: 38px;
    border: 0;
    line-height: 38px;
  }
  .navi-box{
    margin: -44px 19px 0;
    width: 407px;
    height: 85px;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 7px 0 #ebebeb;
  }
  .messageCard{
    margin-top: 28px;
    width: 92%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 0 11px 0 rgb(186 186 210 / 50%);
  }
  .btnLxw{
    margin: 0;
    padding: 0;
    width: 86px;
    height: 29px;
    border: 1px solid #ffae34;
    border-radius: 14px;
    font-size: 13px;
    background-color: #ff9834;
    color: #fff;
    line-height: 29px;
  }
  .padding-top-xl{
    padding-top: 29px;
  }
  ::v-deep .el-dialog {
    height: auto;
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    margin: 25vh auto 50px auto !important;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding: 0 25px;
  }
  .saveText{
    font-size: 14px;
    margin-bottom: 17px;
    color: red;
    text-align: center;
  }
  .popupBut{
    width: 200px;
    background: #f08307;
    border-radius: 10px !important;
    color: #ffffff;
    border: 0;
    font-size: 18px;
    border-radius: 21px !important;
    height: 46px;
  }
  .examineBut{
    width: 90%;
    height: 40px;
    border: 1px solid #ffae34;
    border-radius: 14px;
    font-size: 13px;
    background-color: #ff9834;
    color: #fff;
    line-height: 40px;
    text-align: center;
    margin-top: 20px;
  }
  .asideFloatBox {
    z-index: 1000;
    position: fixed;
    bottom: 118px;
    right: 22px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 137px;
    height: 42px;
    border-radius: 21px;
    background-image: linear-gradient(112deg, #ff9834 9%, #ff4600 91%);
  }
  .imgContainer {
    width: 40px;
    height: 35px;
    padding-left: 4px;
  }
  .titleContainer{
    flex-grow: 1;
    margin: auto;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
  }
  .reminderText{
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    color: #6666;
  }
</style>
